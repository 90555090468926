<template>
    <ion-page>
        <ion-content>
            <breadcrumbs-component/>
            <login-component/>
        </ion-content>
    </ion-page>
</template>

<script>
    import BreadcrumbsComponent from "../components/BreadcrumbsComponent";
    import LoginComponent from "../components/LoginComponent";
    import {IonPage, IonContent} from "@ionic/vue";

    export default {
        name: "Login",
        components: {
            LoginComponent,
            IonPage, IonContent,
            BreadcrumbsComponent
        }
    }
</script>

<style scoped>
</style>