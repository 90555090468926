<template>
  <main id="main" class="sitemain">
  <div class="page-title page-title--small align-left" style="background-color: #53bd6c!important;">
    <div class="container">
      <div class="page-title__content">
        <h1 class="page-title__name">Giriş Yap veya Kayıt Ol</h1>
        <p class="page-title__slogan">Lütfen kayıtlı iseniz giriş yapın eğer ki değilseniz bilgilerinizi doldurup kayıt olunuz.</p>
      </div>
    </div>
  </div>
  </main>
</template>

<script>
export default {
  name: "BreadcrumbsComponent"
}
</script>

<style scoped>

</style>