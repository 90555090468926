<template>
  <div class="site-content">
    <div class="checkout-area">
      <div class="container">
        <div class="row">

          <div class="col-lg-6">
            <div class="billing-form">
              <h2>Giriş Yap</h2>
              <form action="" @submit="sendLoginForm" class="billingForm">
                <div class="field-group">
                  <h3></h3>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="field-input">
                        <ion-label for="email">Email <span class="required">*</span></ion-label>
                        <ion-input type="text" value="" name="email" id="email" required/>
                        <input type="hidden" value="ionic" name="device_name" id="device_name"/>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="field-input">
                        <ion-label for="password">Şifre <span class="required">*</span></ion-label>
                        <ion-input type="password" placeholder="" value="" name="password"
                                   id="password" required/>
                      </div>
                    </div>


                  </div>
                </div><!-- .field-group -->


                <div class="field-submit">
                  <ion-button type="submit" name="submit">
                    Giriş Yap
                  </ion-button>
                </div>
              </form><!-- .billingForm -->
            </div><!-- .checkout-form -->
          </div>
          <div class="col-lg-6">
            <div class="billing-form">
              <h2>Kayıt Ol</h2>
              <form ref="signup" action="#" @submit="sendForm" class="billingForm">
                <ion-label hidden="true">
                  Kayıt ol sayfasından şu an için sadece şirketler kayıt olabilir. Şirket bilgilerinizi yanlış girerek
                  kayıt olmayınız!
                </ion-label>
                <div class="field-group">
                  <ion-label>Şirket Bilgileri <span class="required">*</span></ion-label>
                  <hr>
                  <div class="row">

                    <div class="col-md-6">
                      <div class="field-input">
                        <ion-label for="company_name">Şirket Adı <span class="required">*</span></ion-label>
                        <ion-input type="text" value="" name="company_name" id="company_name" required/>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="field-input">
                        <ion-label for="work_type_id">Şirket Tipi <span class="required">*</span></ion-label>
                        <ion-select interface="popover" id="work_type" name="select"
                                    @ionChange="selectWorkType" :aria-required="true">
                          <ion-select-option v-for="workType in workTypes" :key="workType.id" :value="workType">
                            {{ workType.name }}
                          </ion-select-option>
                        </ion-select>
                        <ion-input hidden="hidden" name="work_type_id" v-model="selectedWorkType"></ion-input>
                        <ion-input hidden="hidden" name="device_name" value="ionic"></ion-input>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <ion-label>Şirket Yöneticisi Bilgileri <span class="required">*</span></ion-label>
                  <hr>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="field-input">
                        <ion-label for="name">Avatar (zorunlu değil)</ion-label>
                        <input type="file" name="avatar" value="" @change="setImage" style="display: none;"
                               id="avatar" accept="image/png, image/jpeg, image/jpg"/>
                        <template v-if="avatar">
                          <img :src="avatar" alt="profile" id="avatar" style="width: 30px;border-radius: 5px;"/>
                        </template>
                        <ion-button @click="openFileDialog">
                          <ion-icon slot="icon-only" :icon="camera"></ion-icon>
                        </ion-button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="field-input">
                        <ion-label for="name">İsim Soyisim<span class="required">*</span></ion-label>
                        <ion-input type="text" value="" name="name" id="name" required/>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="field-input">
                        <ion-label for="username">Kullanıcı Adı <span class="required">*</span></ion-label>
                        <ion-input type="text" value="" name="username" id="username" required/>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="field-input">
                        <ion-label for="email">E-Posta <span class="required">*</span></ion-label>
                        <ion-input type="email" value="" name="email" id="email" required/>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="field-input">
                        <ion-label for="phone_number">Telefon <span class="required">*</span></ion-label>
                        <ion-input type="text" value="" name="phone_number" id="phone_number" required/>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="field-input">
                        <ion-label for="location">Şirket Lokasyonu <span class="required">*</span></ion-label>
                        <GMapMap
                            :center="centerData"
                            :zoom="5"
                            map-type-id="terrain"
                            ref="map"
                            :options="mapOptions"
                            style="width: 100%; height: 20rem"
                        >
                          <GMapMarker
                              :position="placeData"
                              :clickable="true"
                              :draggable="true"
                              @dragend="markerEvent"
                          />
                        </GMapMap>
                        <ion-input hidden="hidden" name="location" v-model="placeDataForm" required></ion-input>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="field-input">
                        <ion-label for="password">Şifre <span class="required">*</span></ion-label>
                        <ion-input type="password" value="" name="password" id="password" required/>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="field-input">
                        <ion-label for="password_confirmation">Şifreni Tekrar Et <span class="required">*</span>
                        </ion-label>
                        <ion-input type="password" value="" name="password_confirmation" id="password_confirmation"
                                   required/>
                      </div>
                    </div>
                  </div>
                </div><!-- .field-group -->


                <div class="field-group">
                  <div class="field-check">
                    <ion-label @click="showConsent" id="present-alert">
                      Gizlilik Politikasını okudum ve kabul ediyorum.
                    </ion-label>
                    <ion-checkbox name="consent" type="checkbox" id="pp" v-model="consent" required/>
                  </div>
                </div>
                <div class="field-submit">

                  <ion-button type="submit" name="submit" :disabled="!consent">
                    Üye Ol
                  </ion-button>
                </div>
              </form><!-- .billingForm -->
            </div><!-- .checkout-form -->
          </div>

        </div>
      </div><!-- .checkout-area -->
    </div>
  </div>
</template>

<script>
import {
  IonAlert,
  IonButton,
  IonCheckbox,
  IonIcon,
  IonInput,
  IonLabel,
  IonSelect,
  IonSelectOption
} from "@ionic/vue";
import {inject, ref} from "@vue/runtime-core";
import {useCookies} from "vue3-cookies";
import {camera} from "ionicons/icons";

export default {
  name: "LoginComponent",
  setup() {
    const $api = inject('api');
    const {cookies} = useCookies();
    const mapOptions = ref({
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      styles: [
        {
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#8ec3b9"
            }
          ]
        },
        {
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1a3646"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.country",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#4b6878"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.land_parcel",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#64779e"
            }
          ]
        },
        {
          "featureType": "administrative.province",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#4b6878"
            }
          ]
        },
        {
          "featureType": "landscape.man_made",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#334e87"
            }
          ]
        },
        {
          "featureType": "landscape.natural",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#023e58"
            }
          ]
        },
        {
          "featureType": "poi",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#283d6a"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#6f9ba5"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#023e58"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#3C7680"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#304a7d"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.icon",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#98a5be"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#2c6675"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "color": "#255763"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#b0d5ce"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#023e58"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#98a5be"
            }
          ]
        },
        {
          "featureType": "transit",
          "elementType": "labels.text.stroke",
          "stylers": [
            {
              "color": "#1d2c4d"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#283d6a"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#3a4762"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry",
          "stylers": [
            {
              "color": "#0e1626"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#4e6d70"
            }
          ]
        }
      ]
    })
    const workTypes = ref([]);
    const consentContent = ref('');
    const consent = ref(false);
    const isOpen = ref(false);
    consentContent.value = $api.consent;
    workTypes.value = $api.workTypes;
    return {
      workTypes,
      consentContent,
      IonInput,
      IonCheckbox,
      IonLabel,
      IonButton,
      IonSelect,
      IonSelectOption,
      IonAlert,
      IonIcon,
      isOpen,
      consent,
      mapOptions,
      cookies,
      camera,
    };
  },
  mounted() {
    const isLogout = this.$router.currentRoute.value.name === 'logout';
    if (isLogout) {
      this.$cookies.remove('token');
      this.$api.removeToken();
    }
  },
  data() {
    return {
      avatar: null,
      selectedWorkType: null,
      centerData: {lat: 39.94538951, lng: 32.94426233},
      placeData: {lat: 39.94538951, lng: 32.94426233},
      placeDataForm: '39.94538951, 32.94426233',
    };
  },
  methods: {
    openFileDialog() {
      document.getElementById("avatar").click();
    },
    setImage: function (e) {
      let f = e.target.files[0];
      const blob = new Blob([f])
      this.avatar = URL.createObjectURL(blob);
    },
    selectWorkType: function (e) {
      this.selectedWorkType = e.detail.value.id;
    },
    showConsent: function () {
      this.openAlert();
    },
    sendForm: function (e) {
      e.preventDefault();
      const formData = new FormData(e.target);
      if (formData.get('avatar').size === 0) {
        formData.delete('avatar');
      }
      if (!this.selectedWorkType) {
        this.$helper.setAlert(
            "Hata",
            '',
            "Lütfen bir çalışma tipi seçiniz",
            [{
              text: "Tamam"
            }]
        ).then(alert => alert.present());
        return;
      }
      this.$api.post('/register', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then((res) => {
            if (res && res.status === 200) {
              this.$cookies.set('token', res.data.token);
              this.$api.setToken(res.data.token);
            }
          });
    },
    sendLoginForm: function (e) {
      const formData = new FormData(e.target);
      e.preventDefault();
      this.$api.post('/login', formData).then((res) => {
        if (res && res.status === 200) {
          this.$cookies.set('token', res.data.token);
          this.$api.setToken(res.data.token);
          this.$router.push('/list');
        }
      });
    },
    markerEvent: function (e) {
      const data = {lat: parseFloat(e.latLng.lat().toFixed(8)), lng: parseFloat(e.latLng.lng().toFixed(8))};
      this.centerData = data;
      this.placeData = data;
      this.placeDataForm = `${e.latLng.lat().toFixed(8)}, ${e.latLng.lng().toFixed(8)}`;
    },
    openAlert: function () {
      this.$helper.setAlert(
          "Sözleşme İçeriği",
          "Lütfen Okuyup Kabul Ediniz",
          this.consentContent,
          [{
            text: "Kabul Ediyorum",
            handler: () => {
              this.consent = true;
            }
          }]
      ).then(alert => alert.present());
    }
  },
}
</script>

<style>
</style>